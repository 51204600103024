<template>
        <div class="container mt-4 mb-7 mt-md-12 mb-md-15 py-0">
            <Title
            :title="$t('Footer.LegalInformation')"
            />
            <div class="mt-5 mt-md-9">
            <ContainerInfoCards
            :containerType="1"
            :cards="cards"
            />
            </div>
        </div>
</template>

<script>
import Title from '@/components/global/headings/Title.vue'
import ContainerInfoCards from '@/components/global/containers/ContainerInfoCards.vue'

export default {
    components: {
        Title,
        ContainerInfoCards,
    },
    data() {
        return {
            cards: [
                {
                    title: this.$t('Legal.PrivacyPolicy'),
                    desc: this.$t('Legal.PrivacyPolicyDesc'),
                    link: {
                        name: 'MoreLegalInformation',
                        params: {
                            type: 'privacy-policy'
                        }
                    }
                },
                {
                    title: this.$t('Legal.TermsOfUse'),
                    desc: this.$t('Legal.TermsConditionsDesc'),
                    link: {
                        name: 'MoreLegalInformation',
                        params: {
                            type: 'terms-of-use'
                        }
                    }
                },
            ]
        }
    }
}
</script>

<style>

</style>